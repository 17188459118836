<template>
  <header
    ref="header"
    class="!max-w-screen fixed top-0 z-20 flex w-screen justify-between bg-white lg:w-full"
    :class="{ 'z-1002': UIStore.hasOpenSidebar }"
  >
    <div class="flex w-full flex-col">
      <template v-if="is_master">
        <AdminHighlight />
      </template>
      <div class="flex w-full items-center justify-between px-4 py-4 lg:px-14 lg:py-6">
        <div class="flex">
          <div class="!lg:items-start flex flex-col !items-end lg:flex-row lg:gap-14">
            <a
              v-if="whiteLabel === null || whiteLabel?.show_eletromidia_logo"
              :href="
                currentRoute.includes('resultados') || currentRoute === '/'
                  ? whiteLabel === null
                    ? '/'
                    : `/${whiteLabel?.code}`
                  : '/resultados'
              "
              class="flex h-full items-center justify-center"
            >
              <img
                loading="lazy"
                :src="LogotipoELT"
                :alt="'Logotipo Eletromidia Aqui'"
                :width="100"
                :class="[isMobile ? '!max-w-[76px]' : '!max-w-[76px]']"
              />
            </a>
            <a v-if="whiteLabel != null" :href="`/${whiteLabel?.code}`" class="flex h-full items-center justify-center">
              <img
                loading="lazy"
                :src="whiteLabel?.logo.image"
                :alt="`Logotipo ${whiteLabel?.name}`"
                :width="whiteLabel?.logo.width"
                :class="isMobile && '!max-w-[78px] mt-4'"
                class="block"
              />
            </a>
          </div>
        </div>
        <nav class="flex w-full items-center">
          <Button
            v-if="whiteLabel === null && props.path == '/'"
            class="ml-auto md:ml-20"
            type="button"
            @click="createAd"
          >
            <span class="!text-lg font-bold"> anunciar agora </span>
          </Button>

          <!-- <Menubar
            v-if="props.path === '/' && isMobile == false"
            :model="whiteLabel === null ? institutionalLinks : institutionalLinksWhiteLabel"
            class="none border-none bg-white !text-lg lg:block"
          /> -->

          <div class="ml-auto flex">
            <div v-if="UserStore.isLoggedAsUser && !isMobile" class="mr-5 flex min-w-fit items-center">
              <template v-if="is_master">
                {{ emailOrNameAccountSelected }}
                <template v-if="emailOrNameAccountSelected">
                  <p class="ml-5 text-neutral-low-medium">Acessando conta de</p>
                  <span class="ml-2 rounded-sm px-2 py-0 text-xl font-bold text-primary-default">
                    <i class="uil uil-users-alt mr-1"></i> {{ emailOrNameAccountSelected }}
                  </span>
                </template>
                <Button outlined class="ml-8" type="button" @click="UIStore.toggleSidebar(SidebarEnum.ADMIN)">
                  <span class="px-3 font-semibold text-neutral-low-dark"> Opções Admin </span>
                  <i class="uil uil-cog" />
                </Button>
              </template>
              <template v-else>
                <template v-if="agency.accountsSelect && agency.accountsSelect.length > 1">
                  <div class="mr-5 flex items-center">
                    <p class="ml-6 mr-3 text-neutral-low-medium">Você está na conta de:</p>
                    <div class="flex items-center">
                      <Dropdown
                        v-model="account"
                        :filter="agency.accountsSelect.length > 15"
                        :options="agency.accountsSelect"
                        optionLabel="account.name"
                        append-to="self"
                        input-class="min-w-[130px] !p-[0.8rem]"
                        placeholder="Escolha uma conta..."
                        class="max-h-[36px]"
                        @change="showAccountDialog()"
                      />
                    </div>
                  </div>
                </template>
              </template>
            </div>

            <Notifications v-if="UserStore.isLogged" class="mr-3 flex items-center" />

            <div v-if="verifyUrl()" class="relative flex empty:hidden">
              <span
                v-if="UserStore.isLogged"
                class="absolute inline-flex h-full w-full rounded-full bg-primary-default opacity-0"
                :class="{
                  'opacity-60 animate-ping': updateCart,
                }"
              />
              <Button
                v-if="props.path !== '/'"
                :key="mediaPlanLabel && cartCount"
                type="button"
                class="p-button p-button-outlined bt-mob btn-carrinho focus:!shadow- my-auto !overflow-visible"
                :style="{ backgroundColor: '#616161', color: '#FFFFFF' }"
                icon="uil uil-shopping-cart"
                :label="mediaPlanLabel"
                badgeClass="custom-badge"
                :badge="cartCount === 0 ? null : cartCount.toString()"
                @click="handleRedirectToMediaPlan()"
              >
                <template #icon>
                  <i class="uil uil-shopping-cart lg:mr-3" :style="{ color: '#A9A9A9' }"></i>
                </template>
              </Button>
            </div>

            <div class="relative">
              <span
                v-if="UserStore.isLoggedAsUser"
                class="absolute inline-flex h-full w-full rounded-full bg-status-positive-light2 opacity-0"
                :class="{
                  'opacity-60 animate-ping': favoriteChanged,
                }"
              />
              <Button
                type="button"
                aria-controls="overlay_menu"
                class="without-border p-button p-button-outlined header-button max-h-12 min-w-fit !text-neutral-low-dark focus:!shadow-none lg:ml-3"
                :class="UserStore.isLoggedAsUser && '!border-neutral-gray6'"
                @click="handleMenu"
              >
                <template v-if="UserStore.isLoggedAsUser || UserStore.isLoggedAsGuestWithEmailChange">
                  <!-- <template v-if="userProfilePhotoUrl || google_auth.profile">
                    <figure
                      class="h-[18px] w-[18px] rounded-md bg-cover"
                      :style="`background-image: url(${userProfilePhotoUrl || google_auth.profile})`"
                    />
                  </template>
                  <template v-else>
                    <Avatar v-if="name" :label="name.charAt(0)" />
                  </template> -->
                  <div class="mr-3">
                    <UserAvatar :photo="UserStore.photo" />
                  </div>
                  <template v-if="!isMobile">
                    <span class="mx-3 whitespace-nowrap font-semibold text-neutral-low-dark">
                      {{ UserStore.greetingName }}
                    </span>
                  </template>
                  <i class="uil-bars text-xl" />
                </template>
                <template v-else>
                  <span class="px-3 !text-lg font-semibold text-neutral-low-dark"> entrar </span>
                  <i class="uil-bars text-xl" />
                </template>
              </Button>
            </div>
            <div class="relative">
              <Menu
                id="overlay_menu"
                ref="menu"
                :model="menuItems"
                class="overlay-menu !-left-64 !top-11 min-w-64"
                appendTo="self"
                popup
              >
                <template #submenuheader="{ item }">
                  <div class="flex w-full items-center">
                    <span :class="item.icon" class />
                    <span class="text-primary font-bold">{{ item.label }}</span>
                    <span :class="item.iconEnd" class="ml-auto" />
                  </div>
                </template>

                <template #item="{ item, props }">
                  <a
                    :href="item.url"
                    :target="item.target"
                    class="flex !w-full hover:bg-neutral-light7"
                    v-bind="props.action"
                    @click="item.handleFunction"
                  >
                    <span v-if="item.icon" :class="item.icon" class="mr-2" />
                    <span class="whitespace-nowrap !p-0">{{ item.label }}</span>
                    <span :class="item.iconEnd" class="ml-auto" />
                  </a>
                </template>
              </Menu>
            </div>
          </div>
        </nav>
      </div>
      <div class="w-full border-b border-neutral-high-light"></div>
    </div>

    <Dialog
      v-model:visible="accountDialog"
      class="account-dialog-confirm"
      modal
      header="Você está trocando de conta! "
      :style="{ width: '40rem' }"
    >
      <div class="mb-8">
        Você está mudando para acessar a conta de <strong>{{ account.account.name }}</strong
        >, gostaria de continuar?
      </div>
      <div class="flex justify-between gap-2">
        <Button label="Não" outlined @click.prevent="accountDialog = false" />

        <Button label="Trocar" @click.prevent="changeAccount()" />
      </div>
    </Dialog>
    <Sidebar
      v-model:visible="isAdminSidebarVisible"
      :blockScroll="true"
      class="min-w-[468px]"
      @hide="handleSidebarHide(SidebarEnum.ADMIN)"
    >
      <br /><br /><br />
      <template #header>
        <div class="align-items-center flex gap-2">
          <strong class="text-2xl font-bold">Opções Administrativas</strong>
        </div>
      </template>

      <section v-auto-animate class="flex flex-col">
        <div
          class="cursor-pointer text-lg text-black transition-all duration-300 hover:!text-[#ff4f00]"
          @click="!showSearchUser ? handleAdminOptions('search') : null"
        >
          <strong>Buscar por Nome, E-mail ou ID do Pedido. Ex: 422</strong> <i class="uil-search ml-4"></i>
        </div>

        <div v-if="showSearchUser" class="my-6 flex flex-col">
          <span class="relative mb-6 w-full">
            <i
              class="uil text-surface-400 dark:text-surface-600 absolute left-3 top-2/4 -mt-2"
              :class="isLoadingSearchAccount ? 'uil-spinner animate spin' : 'uil-search'"
            />
            <InputText
              v-model="searchAccountByAdmin"
              autofocus
              placeholder="Buscar por Nome, E-mail ou ID do Pedido. Ex: 422"
              class="!pl-10"
              @input="handleSearchAccountByNameOrEmail"
            />
          </span>
          <Dropdown
            v-model="account"
            :options="agency && agency.accountsSelect"
            optionLabel="account.name"
            :loading="isLoadingSearchAccount"
            :disabled="isLoadingSearchAccount || !agency.accountsSelect.length"
            placeholder="Selecione uma conta"
            class="w-full"
            @change="showAccountDialog()"
          />
        </div>
      </section>
      <span class="my-6 block h-[1px] w-full bg-neutral-light8" />
      <!-- <section v-auto-animate class="flex flex-col">
        <div
          class="mb-6 cursor-pointer text-lg text-black transition-all duration-300 hover:!text-[#ff4f00]"
          @click="!showCreateUser ? handleAdminOptions('create') : null"
        >
          <strong>Criar novo usuário</strong> <i class="uil-user-plus ml-4"></i>
        </div>

        <CreateNewUserForm :show="showCreateUser" />
      </section> -->
    </Sidebar>
  </header>
  <Cart :isCartSidebarVisible="isSidebarVisible('cart')" @close-cart-sidebar="handleSidebarHide('cart')" />
  <MenuSidebar
    v-if="isMobile"
    :is-mobile="props.isMobile"
    :is-menu-sidebar-visible="isSidebarVisible('menu')"
    :name="name"
    :photo="userProfilePhotoUrl || google_auth.profile"
    :message="UserStore.greetingName"
    :menu-items="menuItems"
    :logo="LogotipoELT"
    @close="handleSidebarHide('menu')"
  />
  <UnloggedDialog
    :open="guestModalControl.isOpen"
    title="Entre ou crie sua conta"
    action-label="avançar"
    action-icon="uil-angle-right"
    @auth:done="handleGuestAuth"
    @update:visible="guestModalControl.update"
  />
  <template v-if="!UserStore.isTempEmail && UserStore.isLogged">
    <Dialog
      v-if="
        !UserStore.phone &&
        (!UserStore.addresses ||
          UserStore.addresses.length === 0 ||
          (!UserStore.addresses[0]?.phone && !UserStore.addresses[1]?.phone))
      "
      v-model:visible="openPhoneRegisterDialog"
      :closable="false"
      :draggable="false"
      class="phone-register-dialog"
      modal
    >
      <template #header>
        <div class="flex w-full items-start justify-between">
          <h3 class="mb-2 text-3xl font-bold lg:text-4xl">Completar cadastro</h3>
        </div>
      </template>
      <p class="text-neutral-low-dark">Informe seu número de telefone para completar seu cadastro</p>

      <form id="form-phone-register">
        <div class="mt-12 flex items-center">
          <FormItem
            class="flex items-center !pt-0"
            type="mask"
            name="register.phone"
            placeholder="Telefone"
            :register="phoneRegisterForm.register"
            mask="['(##) #####-####', '(##) ####-####']"
            :validator="validatePhone"
          />
          <button
            type="button"
            class="ml-4 self-center rounded-lg bg-orange-600 px-8 py-4 font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:hover:bg-gray-400"
            @click.prevent="handlePhoneRegisterSubmit()"
          >
            completar
          </button>
        </div>
      </form>
    </Dialog>
  </template>
</template>

<script setup>
import UnloggedDialog from "@components/Dialogs/UnloggedDialog.vue";
import FormItem from "@components/FormItem/FormItem.vue";
import { SidebarEnum } from "@enums/SidebarEnum";
import useAuth from "@hooks/useAuth";
import useDialogControl from "@hooks/useDialogControl";
import { useStore } from "@nanostores/vue";
import { updateUser } from "@services/user";
import { useForm } from "@vorms/core";
import Cookies from "js-cookie";
import debounce from "lodash/debounce";
import { storeToRefs } from "pinia";
import { useToast } from "primevue/usetoast";
import { computed, getCurrentInstance, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from "vue";

import LogotipoELT from "@/assets/logos/logo-eletromidia_aqui.svg";
import AdminHighlight from "@/components/AdminHighlight.vue";
import Cart from "@/components/Cart/Cart.vue";
import Notifications from "@/components/Notifications/Notifications.vue";
import NewsTicker from "@/components/pages/Home/NewsTicker.vue";
import { useIsMobile, usePersistentRoute, usePixelRatio } from "@/hooks";
import {
  rotaAnuncios,
  rotaBibliotecaMidias,
  rotaCadastro,
  rotaColaboradores,
  rotaContas,
  rotaDashboard,
  rotaFavoritos,
  rotaIdentificacao,
  rotaPaginaInicial,
  rotaPerfil,
  rotaPlanoDeMidia,
  rotaResultados,
  routeFaturamento,
} from "@/routes";
import { getUser } from "@/services/user";
import { $agency, fetchCollaborators, fetchUserAccountsSelect } from "@/stores/accounts";
import { useCartStore } from "@/stores/CartStore";
import { useUIStore } from "@/stores/UIStore";
import { useUserStore } from "@/stores/UserStore";
import { trackButton } from "@/utils/analitycsHelper/button";
import * as analitycsTransactionHelper from "@/utils/analitycsHelper/transaction";
import { getZoomFactor } from "@/utils/getZoomFactor";
import Logger from "@/utils/Logger/Logger";
import Tracker from "@/utils/Tracker/Tracker";

import CreateNewUserForm from "./Admin/CreateNewUserForm.vue";
import MenuSidebar from "./Sidebars/MenuSidebar/MenuSidebar.vue";
import UserAvatar from "./UserAvatar.vue";

const { proxy } = getCurrentInstance();
const { currentRoute } = usePersistentRoute();
const { pixelRatio } = usePixelRatio();
const { isMobile } = useIsMobile();
// usePersistentRoute();
const CartStore = useCartStore();
const UserStore = useUserStore();
const UIStore = useUIStore();
const authHook = useAuth();
const { activeSidebar, favoriteChanged } = storeToRefs(UIStore);

const { cartCount } = storeToRefs(CartStore);
const { is_master, is_partner, account_id, id, name, email, google_auth, token } = storeToRefs(UserStore);
const toast = useToast();
const updateCart = ref(false);
const menu = ref();
const header = ref();
const userProfilePhotoUrl = ref("");
const emailOrNameAccountSelected = ref("");
const mediaPlanLabel = ref("");
const openPhoneRegisterDialog = ref(true);
const account = ref("");
const searchAccountByAdmin = ref("");
const isLoadingSearchAccount = ref(false);
const accountDialog = ref(false);
const isAdminSidebarVisible = ref(UIStore.isSidebarOpen("admin"));
const agency = useStore($agency);
const guestModalControl = useDialogControl();

const showCreateUser = ref(false);
const showSearchUser = ref(false);
const isAuthorizedRoute = ref(false);

const phoneRegisterForm = useForm({
  validateMode: "input",
  reValidationMode: "input",
  initialValues: {},
  onSubmit() {
    dispatchUpdateUser();
  },
});

const dispatchUpdateUser = async () => {
  const { phone } = phoneRegisterForm.values?.register ?? {};
  const userData = await updateUser(UserStore.id, {
    phone,
  });

  await UserStore.updateUser({ ...userData });
  toast.add({
    severity: "success",
    summary: "Telefone adicionado com sucesso!",
    life: 3000,
  });
};

const handlePhoneRegisterSubmit = () => {
  phoneRegisterForm.handleSubmit();
};

const handleAdminOptions = (option) => {
  switch (option) {
    case "search":
      showSearchUser.value = true;
      showCreateUser.value = false;
      break;
    case "create":
      showCreateUser.value = true;
      showSearchUser.value = false;
      break;
    default:
      console.warn("opção invalida:", option);
      break;
  }
};

const userNameMessage = reactive({});
const googleSoftLogin = reactive({});
const institutionalLinks = ref([
  {
    label: "agências parceiras",
    url: "https://parceiros.eletromidia.com.br/?utm_source=site&utm_medium=na&utm_campaign=site_aqui",
    class: "font-bold",
    target: "_blank",
  },
]);

const handleGuestAuth = () => {
  guestModalControl.close();
};

const createAd = () => {
  Tracker.getInstance().track(Tracker.EVENT_BUTTON_ANUNCIAR_AGORA, trackButton("Anunciar agora cabeçalho"));
  return (document.location.href = rotaResultados.url);
};

const institutionalLinksWhiteLabel = ref([]);

const hasInviteOrAcceptedAccount = computed(() => {
  const { accountsPermissions } = agency.value;

  return accountsPermissions.length > 0;
});

const menuItems = computed(() => {
  const handleLogout = {
    label: "sair",
    iconEnd: "uil uil-signout",
    command: () => authHook.logout(),
  };

  const separatorEntry = { separator: true };

  const materialsAndHelp = {
    label: "Materiais e ajuda",
    iconEnd: "uil uil-info-circle",
    items: [
      {
        label: "Mídia Kit",
        url: "https://storage.googleapis.com/elt-smb-prod/assets/docs/MIDIA_KIT_INSTITUCIONAL_29_04_24.pdf",
        target: "_blank",
      },
      {
        label: "Especificações para Criação",
        url: "https://storage.googleapis.com/elt-smb-prod/assets/docs/2025/02/smb-especificacoes-tecnicas-fev25.pdf",
        target: "_blank",
      },
      {
        label: "Guia de Criação",
        url: "https://storage.googleapis.com/elt-smb-prod/assets/docs/2024/04/guia-pratico-de-criacao-para-out-of-home-eletromidia.pdf",
        target: "_blank",
      },
      {
        label: "Central de conhecimento",
        url: "https://eletromidia.thinkific.com/courses/trilha-do-conhecimento",
        target: "_blank",
      },
      {
        label: "Suporte",
        url: "https://api.whatsapp.com/send/?phone=%2B5511963815044",
        target: "_blank",
      },
    ],
  };

  const userManager = () => ({
    label: "Gerenciador",
    iconEnd: "uil uil-cog",
    items: [
      {
        label: "Meu Perfil",
        url: rotaPerfil.url,
      },
      {
        label: "Minhas Contas",
        url: rotaContas.contas.url,
      },
      {
        label: "Meus Colaboradores",
        url: rotaColaboradores.url,
      },
      {
        label: "Programa de Indicação",
        url: "https://home.eletromidia.com.br/aqui/programadeindicacao?bl_ref=D5ND2",
        target: "_blank",
      },
    ],
  });

  const historyMenu = () => ({
    label: "Meu histórico",
    iconEnd: "uil uil-user",
    items: [
      { label: "Criar Novo Anúncio", url: rotaResultados.url },
      { label: "Meus Anúncios", url: rotaAnuncios.url },
      { label: "Biblioteca de Midias", url: rotaBibliotecaMidias.url },
      { label: "Meus favoritos", url: rotaFavoritos.url },
      { label: "Faturamento", url: routeFaturamento.url },
    ],
  });

  if (typeof window === "undefined") return {};

  const currentPath = window.location.pathname;

  const loginAndRegisterMenuEntries = () => [
    {
      label: "login",
      ...(currentPath === rotaPaginaInicial.url ||
      currentPath === rotaCadastro.url ||
      currentPath === rotaIdentificacao.url
        ? { url: rotaIdentificacao.url }
        : { handleFunction: openLoginModal }),
      icon: "uil uil-user",
    },
    {
      label: "quero me cadastrar",
      url: rotaCadastro.url,
      icon: "uil uil-user",
    },
  ];

  if (googleSoftLogin.validate) {
    Logger.info("menu: google soft login");
    return [{ label: "complementar", url: rotaCadastro.url, icon: "uil uil-user" }, handleLogout];
  }

  if (UserStore.isLoggedAsUser) {
    Logger.info("menu: logged as user");
    const accountsEntry = { label: "contas", url: rotaContas.contas.url, icon: "uil uil-users" };
    const dashboardEntry = { label: "Dashboard", url: rotaDashboard.url, icon: "uil uil-chart-bar" };

    return [
      historyMenu(),
      ...(agency.value && hasInviteOrAcceptedAccount.value ? [accountsEntry] : []),
      ...(UserStore.is_partner ? [dashboardEntry] : []),
      separatorEntry,
      userManager(),
      separatorEntry,
      materialsAndHelp,
      separatorEntry,
      handleLogout,
    ];
  }

  if (UserStore.isLoggedAsGuestWithEmailChange) {
    Logger.info("menu: logged as guest with email change");
    const menuEntryEditProfile = {
      label: "Gerenciador",
      iconEnd: "uil uil-cog",
      items: [{ label: "Completar perfil", url: rotaPerfil.url, icon: "uil uil-user" }],
    };

    return [
      historyMenu(),
      separatorEntry,
      menuEntryEditProfile,
      separatorEntry,
      materialsAndHelp,
      separatorEntry,
      handleLogout,
    ];
  }
  Logger.info("menu: default");
  return [...loginAndRegisterMenuEntries(), separatorEntry, materialsAndHelp];
});

const openLoginModal = () => {
  guestModalControl.open();
  handleSidebarHide("menu");
};

const props = defineProps({
  path: {
    type: String,
  },
  whiteLabel: {
    type: Object,
  },
});

const verifyUrl = () => {
  return (
    !props.path.startsWith("/pagamento") &&
    !props.path.startsWith("/identificacao") &&
    !props.path.startsWith("/cadastro")
  );
};

const redirectToResume = () => {
  document.location.href = "/resumo";
};

const toggle = (event) => {
  menu.value.toggle(event);
};

const getHeight = () => {
  if (header.value) {
    return header.value.clientHeight;
  }
};

const dispatchFetchCart = async () => {
  CartStore.fetchCart();
};

const toggleSidebar = (name) => {
  if (props.path.includes("enviar-arquivo")) {
    redirectToResume();
  }
  UIStore.toggleSidebar(name);

  if (UIStore.isSidebarOpen(SidebarEnum.CART)) {
    analitycsTransactionHelper.wrapperTrackerTransaction(CartStore, Tracker.EVENT_VIEW_CART);
  }
};

const handleSidebarHide = (name) => {
  UIStore.closeSidebar(name);
};

const handleRedirectToMediaPlan = () => {
  document.location.href = rotaPlanoDeMidia.url;
};

const isSidebarVisible = (name) => {
  return UIStore.isSidebarOpen(name);
};

const handleMenu = (event) => {
  isMobile.value ? UIStore.toggleSidebar("menu") : toggle(event);
};

const handleMediaPlanLabel = () => {
  mediaPlanLabel.value = isMobile.value ? "" : "plano de mídia";
};

watch(
  () => UIStore.activeSidebars,
  () => {
    isAdminSidebarVisible.value = UIStore.isSidebarOpen(SidebarEnum.ADMIN);
  },
  { deep: true },
  { immediate: true },
);

onMounted(() => {
  const height = getHeight();
  dispatchFetchCart();

  handleMediaPlanLabel();

  isAuthorizedRoute.value = window.location.pathname === rotaPaginaInicial.url;
});

watch(cartCount, (newCount) => {
  updateCart.value = true;
  setTimeout(() => {
    updateCart.value = false;
  }, 500);
});

/**
 * Agency Accounts
 */
const showAccountDialog = () => {
  if (account.value != null) accountDialog.value = true;
};

const changeAccount = () => {
  Cookies.set("account_id", account.value.account_id);
  const user = {
    account_id: account.value.account_id,
    account_name: account.value.account.name,
  };
  localStorage.setItem("account_access", JSON.stringify(user));

  window.location.reload();
};

watch(agency, (n) => {
  let accountIdFromCookie = Cookies.get("account_id");
  let accountSelected;
  const accountsSelectList = n?.accountsSelect ?? [];
  const accId = accountIdFromCookie ?? account_id;

  if (accountsSelectList.length >= 1) {
    accountSelected = accountsSelectList.find((el) => accId == el.account_id);
    account.value = accountSelected;
  }
});

watch(
  agency,
  () => {
    isLoadingSearchAccount.value = false;
  },
  { deep: true },
);

watch(
  () => email,
  async () => {
    if (!email.value) {
      return;
    }

    const userIdHash = await Tracker.hash(email.value);
    Tracker.getInstance().track(Tracker.EVENT_USER_DATA, {
      user_id: userIdHash,
    });
  },
  { immediate: true },
);

onMounted(async () => {
  if (agency.value.accountsSelect.length <= 0 && UserStore.isLogged) {
    fetchUserAccountsSelect();
  }
});

onMounted(() => {
  UserStore.isLogged && fetchCollaborators();
  fetchUserInfo(id.value);

  if (is_master) {
    handleGetAccount();
  }
});
onMounted(() => {
  const header = document.querySelector("header");

  if (!header) return;

  const zoomFactor = getZoomFactor();

  const headerHeight = header.offsetHeight * zoomFactor;

  nextTick(() => {
    document.body.style.paddingTop = `${headerHeight}px`;
  });
});

const handleSearchAccountByNameOrEmail = debounce(() => {
  if (UserStore.isLogged) {
    fetchUserAccountsSelect({ search: searchAccountByAdmin.value });
    isLoadingSearchAccount.value = true;
  }
}, 750);

const fetchUserInfo = async (id) => {
  if (!id) {
    return;
  }

  try {
    const userData = await getUser(id);
    userProfilePhotoUrl.value = userData.profile || "";
  } catch (error) {
    console.error("Erro ao carregar usuario", error);
  }
};

const handleGetAccount = async () => {
  if (!email) return;
  let account_access = localStorage.getItem("account_access");
  const account = JSON.parse(account_access);
  if (account_access && account.account_id != UserStore.account_id) {
    emailOrNameAccountSelected.value = account.account_name;
  } else {
    emailOrNameAccountSelected.value = null;
  }
};

const handleScroll = () => {
  if (!isMobile.value && menu.value && menu.value.visible) {
    menu.value.hide();
  }
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style lang="scss" scoped>
:deep(.p-tieredmenu.p-tieredmenu-overlay) {
  top: 37px !important;
  right: 0 !important;
  left: auto !important;
}

#accounts-select {
  width: 195px;
  border-radius: 8px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.p-button.btn-carrinho .p-badge.custom-badge) {
  top: -6px;
  right: 5px;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-weight: normal;
  @apply bg-status-positive-light;

  @media screen and (max-width: 768px) {
    right: 2px !important;
  }
}

.menubar-items {
  @media (min-width: 1280px) and (max-width: 1366px) {
    font-size: 0.8rem !important;
  }
}

.p-button.without-border {
  border: none !important;
}
//removendo bg de acessibilidade padrão do componente
:deep(.overlay-menu.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content) {
  background: transparent;
}
//forçando hover para cinza que era a cor de foco
:deep(.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link:hover) {
  background: #e5e7eb;
}
</style>

<style lang="scss">
.phone-register-dialog.p-dialog .p-dialog-header {
  padding: 60px 60px 0 60px !important;
}
.phone-register-dialog.p-dialog .p-dialog-content {
  padding: 0 60px 60px 60px !important;

  @media (max-width: 768px) {
    padding: 0 38px 40px 38px !important;
  }
}
</style>
